import type { ReadonlyURLSearchParams } from 'next/navigation';

export const stringifyUrl = (input: {
  href: string;
  searchParams?: ReadonlyURLSearchParams | null;
  getAllSearchParams?: boolean;
}) => {
  const { href, searchParams } = input;

  const [pathname, queryString] = href.split('?');

  const query = new URLSearchParams(queryString);

  const utmParams = getUtmSearchParams(searchParams);
  for (const [key, value] of utmParams.entries()) {
    query.set(key, value);
  }

  if (input.getAllSearchParams) {
    const otherParams = getOtherSearchParams(searchParams);
    for (const [key, value] of otherParams.entries()) {
      query.set(key, value);
    }
  }

  const newQuery = query.toString() ? `?${query.toString()}` : '';

  return `${pathname}${newQuery}`;
};

export const getUtmString = (searchParams?: ReadonlyURLSearchParams | null) => {
  const utmString = getUtmSearchParams(searchParams).toString();
  return utmString ? `?${utmString}` : '';
};

export const getUtmSearchParams = (
  searchParams?: ReadonlyURLSearchParams | null
) => {
  const utmParams = new URLSearchParams();

  [
    'utm_source',
    'utm_medium',
    'utm_campaign',
    'utm_term',
    'utm_content',
  ].forEach((param) => {
    const value = searchParams?.get(param);
    if (value) {
      utmParams.set(param, value);
    }
  });

  return utmParams;
};

export const getOtherSearchParams = (
  searchParams?: ReadonlyURLSearchParams | null
) => {
  const otherParams = new URLSearchParams();

  searchParams?.forEach((value, key) => {
    if (!key.startsWith('utm_')) {
      otherParams.set(key, value);
    }
  });

  return otherParams;
};
