export enum UnleashFlags {
  MIS_72_ENABLE_MATRICULAS_ON_MEU_ISAAC = 'MIS_72_ENABLE_MATRICULAS_ON_MEU_ISAAC',
  MIS_267_ENABLE_INSURANCE = 'MIS_267_ENABLE_INSURANCE',
  B2CB_696_ENABLE_MEU_ISAAC_MAINTENANCE_MODE = 'B2CB_696_ENABLE_MEU_ISAAC_MAINTENANCE_MODE',
  B2CB_814_REDIRECT_TO_LANDING_PAGE = 'B2CB_814_REDIRECT_TO_LANDING_PAGE',
  B2CB_850_ENABLE_DISCOUNT_CAMPAIGN = 'B2CB_850_ENABLE_DISCOUNT_CAMPAIGN',
  REG_2086_ENABLE_CREDIT_CARD_PAYMENT = 'REG_2086_ENABLE_CREDIT_CARD_PAYMENT',
  ISP_123_ENABLE_TEACHING_MATERIAL_PAGE = 'ISP-123-ENABLE-TEACHING-MATERIAL-PAGE',
  MIS_371_ENABLE_COLLECTION_STUDENT_DATA = 'MIS_371_ENABLE_COLLECTION_STUDENT_DATA',
  MIS_116_ALLOW_ENROLLMENT_STATUS_UPDATE = 'MIS_116_ALLOW_ENROLLMENT_STATUS_UPDATE',
  MIS_423_ENABLE_DATA_COLLECTION_FLOW = 'MIS_423_ENABLE_DATA_COLLECTION_FLOW',
  MIS_408_ENABLE_HOME_APP = 'MIS_408_ENABLE_HOME_APP',
  REG_498_ENABLE_NEW_CREDIT_CARD_FEE_FLOW = 'REG_498_ENABLE_NEW_CREDIT_CARD_FEE_FLOW',
  SEC_000_ENABLE_RECAPTCHA_ON_LOGIN = 'SEC_000_ENABLE_RECAPTCHA_ON_LOGIN',
  REG_487_ENABLE_NEW_INSTALLMENTS_OVERDUE_PAGE = 'REG_487_ENABLE_NEW_INSTALLMENTS_OVERDUE_PAGE',
  REG_561_ENABLE_IR_STATEMENTS_PAGE = 'REG_561_ENABLE_IR_STATEMENTS_PAGE',

  /*
   * As flags abaixo são gerenciadas pelo time de Matrículas
   * Qualquer alteração deve ser alinhada com o time responsável
   */
  ONB_201_ENABLE_FIDC = 'ONB_201_ENABLE_FIDC',
  MAT_57_ENABLE_PRE_MATRICULA = 'MAT_57_ENABLE_PRE_MATRICULA',
  MAT_673_DIGITAL_SIGNATURE_BUTTON = 'MAT-673-DIGITAL-SIGNATURE-BUTTON',
  MAT_884_ENABLE_REQUIRED_FIDC_CONFIRMATION = 'MAT-884-ENABLE_REQUIRED_FIDC_CONFIRMATION',
  MAT_884_ENABLE_FORCE_REDIRECT_TO_FIDC_CONFIRMATION = 'MAT-884-ENABLE_FORCE_REDIRECT_TO_FIDC_CONFIRMATION',
  MAT_930_ENABLE_MIDDLEWARE_REDIRECT_FIDC_REQUIRED ='MAT-930-ENABLE-MIDDLEWARE-REDIRECT-FIDC-REQUIRED',

  // TODO: Revisar se flags estão em uso
  ONB_315_FIDC_BUTTON_AB = 'ONB_315_FIDC_BUTTON_AB',
  MIS_277_REQUIRED_FIDC_CONFIRMATION = 'MIS_277_REQUIRED_FIDC_CONFIRMATION',
  CPU_332_ENABLE_MESSAGES_APP = 'CPU_332_ENABLE_MESSAGES_APP',
}
